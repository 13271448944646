.what-we-done-component{
  //background: linear-gradient(90deg, #0C2231 0%, #030A0E 100%);
  background: url("../../assets/what-we-done-bg.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  padding: 53px 100px;
  .content-section{
    .title{
      font-family: 'Roboto Condensed',sans-serif;
      font-weight: 700;
      font-size: 32px;
      line-height: 46px;
      text-align: center;
      letter-spacing: 0.06em;
      color: #FFFFFF;
      text-shadow: 2px 0px 0px #E20183, -2px 0px 0px #02958F;
      margin-bottom: 28px;
    }
    .description{
      font-family: 'Roboto Condensed',sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
      text-align: center;
      color: #FFFFFF;
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 80px;
    }

  }
  .slider-container{
    .slick-slider{
      .slick-arrow{
        &:before{
          content: none;
        }
      }
    }
  }
}
.video-card-component{
  padding: 12px 12.5px;
  width: 240px;
  margin: 0 auto;
  background-image: url("../../assets/video-gradient-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  .video-section{
    height: 423px;
    background: rgba(217, 217, 217, 0.2);
    border-radius: 8px;
  }
  .title{
    font-family: 'Roboto Condensed',sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    margin: 12px 0 20px 0;
  }
  .try-now-btn{
    background: linear-gradient(90deg, #35BDB4 6.48%, #3DB3B2 15.94%, #499FAF 34.32%, #567CA7 59.79%, #614B99 91.08%, #633996 100%);
    border-radius: 11px;
    padding: 6px;
    font-family: 'Roboto Condensed',sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #FFFFFF;
    text-transform: capitalize;
    width: 100%;
  }
}

@media screen and (max-width: 768px){
  .what-we-done-component{
    padding: 34px 24px 51px 24px;
    background: url("../../assets/what-we-done-bg-mb.png");

    .content-section{
      .title{
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 12px;
      }
      .description{
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 40px;
        max-width: 100%;
      }
    }
    .slider-container{
      .slick-slider{
       .slick-list{
         .slick-track{
           .slick-slide{
             transform: scale(0.9);
             transition: all 0.5s;
             &.slick-current{
               transform: scale(1);
               transition: all 0.5s;
             }
           }
         }
       }
      }
    }
  }
  .video-card-component{
    width: 100%;
  }
}
