.hero-section-component{
    min-height: 600px;
    width: 100%;
    background-image: url("../../assets/hero-bg-pattern.png");
    margin-top: 4.5rem;
    display: flex;
    //align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    .content-section{
        padding: 0 0 0 100px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .content1{
            font-family: 'Roboto Condensed',sans-serif;
            font-weight: 700;
            font-size: 40px;
            line-height: 58px;
            color: #FFFFFF;
            margin-bottom: 10px;
        }
        .content2{
            font-family: 'Roboto Condensed',sans-serif;
            font-weight: 700;
            font-size: 40px;
            line-height: 58px;
            letter-spacing: 0.06em;
            color: #FFFFFF;
            text-shadow: 2px 0px 0px #E20183, -2px 0px 0px #02958F;
            margin-bottom: 44px;
        }
        .try-now-btn{
            background: linear-gradient(90deg, #35BDB4 6.48%, #3DB3B2 15.94%, #499FAF 34.32%, #567CA7 59.79%, #614B99 91.08%, #633996 100%);            border-radius: 11px;
            padding: 6px 47px;
            font-family: 'Roboto Condensed',sans-serif;
            font-weight: 700;
            font-size: 24px;
            line-height: 35px;
            color: #FFFFFF;
            text-transform: capitalize;
        }
    }
    .image-section{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        //background-image: url("../../assets/Ripples.png");
        //background-size: contain;
        //background-repeat: no-repeat;
        //background-position: center;
        .phone-img{
            width: 45%;
        }
        /* END Optional styles */
        .video-container {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .video-container video {
            width: 100%;
            height: 100%;
        }

        .play-button-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: auto;
            pointer-events: none;
        }
        .play-button-wrapper #circle-play-b {
            cursor: pointer;
            pointer-events: auto;
        }
        .play-button-wrapper #circle-play-b svg {
            width: 50px;
            height: 50px;
            fill: #fff;
            stroke: #fff;
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 50%;
        }
    }
}




@media screen and (max-width: 768px){
    .hero-section-component{
        min-height: 760px;
        margin-top: 3.5rem;
        flex-direction: column;
        padding: 24px 38px 26px 38px;
        background-image: url("../../assets/mobile-hero-bg-pattern.png");
        background-color: #1A1A1B;
        .content-section{
            padding: 0;
            flex: none;
            .content1{
                font-size: 32px;
                line-height: 46px;
                text-align: center;
                width: 100%;
                br{
                    display: none;
                }
            }
            .content2{
                font-size: 40px;
                line-height: 58px;
                text-align: center;
                margin-bottom: 32px;
                width: 100%;
            }
            .try-now-btn{
                margin: 0 auto;
            }
        }
        .image-section{
            flex: none;
            background-image: none;
            margin-top: 38px;
            .phone-img{
                width: 100%;
            }
        }
    }
}
