.footer-component{
  .footer-container{
    padding: 80px 100px;
    background: #1C1B1B;
    .site-links-section{
      width: max-content;
      margin: 0 auto;
    }
    .footer-item-title{
      font-family: 'Roboto Condensed',sans-serif;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: #FFFFFF;
      margin-bottom: 24px;
    }
    .footer-item-img{
      margin-bottom: 24px;
    }
    .footer-item-list{
      li:not(:last-child){
        margin-bottom: 16px;
      }
      .footer-item-list-content{
        font-family: 'Roboto Condensed',sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.75);

      }
      .social-icons{
        font-size: 16px;
        color: rgba(255, 255, 255, 0.75);
      }
    }
    .footer-description{
      font-family: 'Roboto Condensed',sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      color: #FFFFFF;
    }
  }
  .copy-right-section{
    padding: 20px;
    font-family: 'Roboto Condensed',sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.75);
  }
  .show-xs-device{
    display: none;
  }
}


@media screen and (max-width: 768px){
  .footer-component{
    .show-xs-device{
      display: block;
    }
    .hide-xs-device{
      display: none;
    }
    .mobile-footer-section{
      background: #1C1B1B;
      padding: 40px 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      .footer-item-tile{
        font-family: 'Roboto Condensed',sans-serif;
        font-weight: 700;
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        color: #FFFFFF;
        margin: 0;
      }
    }
  }
}
