.insight-component{
  background: url("../../assets/insight-bg.png") no-repeat bottom;
  padding: 53px 100px;
  background-size: cover;
  .content-section{
    .title{
      font-family: 'Roboto Condensed',sans-serif;
      font-weight: 700;
      font-size: 32px;
      line-height: 46px;
      text-align: center;
      letter-spacing: 0.06em;
      color: #FFFFFF;
      text-shadow: 2px 0px 0px #E20183, -2px 0px 0px #02958F;
      margin-bottom: 28px;
    }
    .description{
      font-family: 'Roboto Condensed',sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
      text-align: center;
      color: #FFFFFF;
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 68px;
    }

  }
  .card-content-section{
    background: linear-gradient(121.08deg, rgba(255, 255, 255, 0.4) 15.5%, rgba(133, 255, 218, 0.1) 80.62%);
    backdrop-filter: blur(16px);
    border-radius: 8px;
    padding: 46px 21px;
    .title{
      font-family: 'Roboto Condensed',sans-serif;
      font-weight: 700;
      font-size: 32px;
      line-height: 46px;
      text-align: center;
      letter-spacing: 0.06em;
      color: #38FFF0;
      margin-bottom: 8px;
    }
    .description{
      font-family: 'Jost',sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      color: #FFFFFF;
      margin: 0;
    }
  }
}

@media screen and (max-width: 768px){
  .insight-component{
    padding: 34px 24px 51px 24px;
    background: url("../../assets/insight-bg-mb.png") no-repeat bottom;
    background-size: cover;
    .content-section{
      .title{
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 12px;
      }
      .description{
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 40px;
        max-width: 100%;
      }
    }
    .card-content-section{
      padding: 32px 28px;
      .col-example:not(:last-child){
        margin-bottom: 40px;
      }
    }
  }
}
