.navbar-component{
  background: #1A1A1B;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  .navbar{
    //background: linear-gradient(121.08deg, rgba(255, 255, 255, 0.4) 15.5%, rgba(255, 133, 133, 0.1) 80.62%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    linear-gradient(121.08deg, rgba(255, 255, 255, 0.4) 15.5%, rgba(255, 133, 133, 0.1) 80.62%);

    backdrop-filter: blur(10px);
    border-radius: 0px 0px 8px 8px;
    padding: 16px 96px;
    .navbar-brand{
      padding: 0;
    }
    .navbar-nav{
      gap: 60px;
      .nav-item{
        display: flex;
        align-items: center;
        .nav-link{
          font-family: 'Roboto Condensed',sans-serif;
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          color: #FFFFFF;
          padding: 0;
          .contact-us-btn-section{
            background: linear-gradient(90deg, #F0701E 0%, #B60CE5 100%);
            padding: 2px;
            border-radius: 11px;
            .contact-us-btn{
              padding: 6px 35px;
              border-radius: 11px;
              background: #332828;
            }
          }
        }
      }
    }
    .show-xs-navbar-nav{
      display: none;
    }
  }
}

#contact-us-modal{
  .modal-dialog{
    .modal-content{
      background: #1A1A1B;
      border-radius: 16px;
      .modal-header{
        border-bottom: none;
        .modal-title{
          font-family: 'Roboto Condensed',sans-serif;
          font-weight: 700;
          font-size: 20px;
          line-height: 120%;
          color: #FFFFFF;
        }
        .close-btn{
          background: transparent;
          border: none;
          outline: none;
          .mobile-title{
            display: none;
          }
        }
      }
      .modal-body{
        .form-content-section{
          display: none;
        }
        .input-feild-section{
          padding: 0 10px;
          //border: 2px solid #00AC9F;
          border-radius: 11px;
          outline: none;
          font-family: 'Roboto Condensed',sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          //color: #7F7F86;
          color: #ffffff;
          display: flex;
          align-items: center;
          min-height: 56px;
          margin-bottom: 32px;
        }
        .input-feild-section::-webkit-outer-spin-button,
        .input-feild-section::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        .input-feild-section[type=number] {
          -moz-appearance: textfield;
        }
        .submit-btn{
          background: linear-gradient(90deg, #35BDB4 6.48%, #3DB3B2 15.94%, #499FAF 34.32%, #567CA7 59.79%, #614B99 91.08%, #633996 100%);          border-radius: 11px;
          padding: 13.5px;
          font-family: 'Roboto Condensed',sans-serif;
          font-weight: 700;
          font-size: 20px;
          line-height: 29px;
          color: #FFFFFF;
          text-transform: capitalize;
          width: 100%;
        }
        .form-outline .form-control~.form-notch div{
          border-color: #00AC9F;
        }
        .form-outline .form-control~.form-label{
          color: #7F7F86;
          //top: 50%;
          //transform: translateY(-50%);
        }
        //.form-outline .form-control:focus~.form-label, .form-outline .form-control.active~.form-label {
        //  top: -14px;
        //  transform: scale(0.8);
        //}
        .thankyou-section{
          .content{
            font-family: 'Roboto Condensed',sans-serif;
            font-weight: 700;
            font-size: 24px;
            line-height: 35px;
            text-align: center;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .navbar-component{
    .navbar{
      padding: 8px 24px;
      .show-xs-navbar-nav{
        display: block;
      }
      .hide-xs-navbar-nav{
        display: none;
      }
      .navbar-nav {
        .nav-item {
          .nav-link{
            font-family: 'Roboto Condensed', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            text-decoration-line: underline;
          }
        }
      }
    }
  }
  #contact-us-modal{
    .modal-dialog{
      .modal-content{
        .modal-header{
          .modal-title{
            display: none;
          }
          .close-btn{
            display: flex;
            align-items: center;
            .mobile-title{
              display: block;
              font-family: 'Roboto Condensed',sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 23px;
              color: #00A1AB;
              margin-left: 15px;
            }
          }
        }
        .modal-body{
          .form-content-section{
            display: block;
            margin-bottom: 32px;
            .title{
              font-family: 'Roboto Condensed',sans-serif;
              font-style: normal;
              font-weight: 700;
              font-size: 32px;
              line-height: 46px;
              text-align: center;
              color: #FFFFFF;
            }
            .sub-title{
              font-family: 'Roboto Condensed',sans-serif;
              font-style: normal;
              font-weight: 700;
              font-size: 40px;
              line-height: 58px;
              text-align: center;
              letter-spacing: 0.06em;
              color: #FFFFFF;
              text-shadow: 2px 0px 0px #E20183, -2px 0px 0px #02958F;
            }
          }
          .input-feild-section{
            background: linear-gradient(121.08deg, rgba(255, 255, 255, 0.19) 15.5%, rgba(133, 255, 167, 0.1) 80.62%);
            backdrop-filter: blur(10px);
            margin-bottom: 20px;
          }
          .form-outline .form-control~.form-notch div{
            border-color: #00AC9F;
          }
          .submit-btn{
            font-size: 24px;
            line-height: 38px;
          }
        }
      }
    }
  }

}
