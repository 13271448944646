.industry-vertical-component{
  //background: linear-gradient(90deg, #0C2231 0%, #030A0E 100%);
  background: url("../../assets/industrials-bg.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  padding: 53px 100px;
  .content-section{
    .title{
      font-family: 'Roboto Condensed',sans-serif;
      font-weight: 700;
      font-size: 32px;
      line-height: 46px;
      text-align: center;
      letter-spacing: 0.06em;
      color: #FFFFFF;
      text-shadow: 2px 0px 0px #E20183, -2px 0px 0px #02958F;
      margin-bottom: 28px;
    }
    .description{
      font-family: 'Roboto Condensed',sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
      text-align: center;
      color: #FFFFFF;
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 80px;
    }

  }
  .spacer{
    width: 100%;
    height: 56px;
  }
  .industry-vertical-container{
    padding: 0;
  }
}

.industry-vertical-card-component{
  padding: 20px;
  border-radius: 13.2355px;
  //background: linear-gradient(313.12deg, #00C495 0%, rgba(0, 198, 150, 0.708333) 30.22%, rgba(192, 192, 192, 0) 56.13%, rgba(139, 139, 139, 0) 103.63%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  .inner-content{
    //padding: 20px;
    //background: linear-gradient(121.08deg, rgba(255, 255, 255, 0) 15.5%, rgba(133, 255, 167, 0.1) 80.62%);
    //backdrop-filter: blur(16.5443px);
    //border-radius: 13.2355px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title{
      font-family: 'Roboto Condensed',sans-serif;
      font-weight: 700;
      font-size: 26.4709px;
      line-height: 38px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      margin-top: 10px;
      margin-bottom: 0;
      text-align: center;
    }

  }
}

@media screen and (max-width: 768px){
  .industry-vertical-component{
    padding: 34px 24px 51px 24px;
    background: url("../../assets/industrials-bg-mb.png");
    .content-section{
      .title{
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 12px;
      }
      .description{
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 40px;
        max-width: 100%;
      }
    }
    .spacer{
      display: none;
    }
    .col-example{
      margin-bottom: 24px;
    }
  }
  .industry-vertical-card-component{
    padding: 12px;
    .inner-content{
      .title{
        font-size: 16px;
        line-height: 23px;
        margin-top: 6px;
      }
    }
  }
}
